AgrimarketControllers.controller('userSubscribeContractFirmController', ['$scope', '$rootScope', '$location', 'currentUserFactory', 'enumFactory', 'configurationFactory', 'parametersFactory', '$routeParams', 'offerService', 'parametersService', 'contractsService', 'contractBaseService', 'colonnesContractBaseCompatibles', '$filter', 'conditionsService', 'colonnesContractTypeCommitmentAndProduct', 'signatureService', 'contractingDelayService', 'typeOrdersService', 'targetPriceService', 'userService', 'dateService',
    function ($scope, $rootScope, $location, currentUserFactory, enumFactory, configurationFactory, parametersFactory, $routeParams, offerService, parametersService, contractsService, contractBaseService, colonnesContractBaseCompatibles, $filter, conditionsService, colonnesContractTypeCommitmentAndProduct, signatureService, contractingDelayService, typeOrdersService, targetPriceService, userService, dateService) {

        var init = function () {
            $scope.paramsCompatibleCommitments = { reverse: false, sortKey: "IdContract", maxContracts: 10, pageNo: 1 };
            $scope.paramsCompatibleBases = { reverse: false, sortKey: "IdContractBase", maxContracts: 10, pageNo: 1 };
            $scope.selectedVariety = {};
            $scope.isSell = false;
            $scope.firstSendSmsMessageDelayedSigning = false;
            $scope.isObjective = false;
            $scope.checkValidCondition = false;
            $scope.showUrlSellConditionAttachment = false;
            $scope.showUrlUtilConditionAttachment = false;
            $scope.idContractCommitment = $routeParams.idContractCommitment;
            $scope.idTypeFixation = $routeParams.idTypeFixation;
            $scope.enumTypeContract = enumFactory.TypeContract;
            $scope.enumTypeCommitment = enumFactory.TypeCommitment;
            $scope.enumTypeOfferCommitment = enumFactory.TypeOfferCommitment;
            $scope.enumTypeTargetPriceMessage = enumFactory.TypeTargetPriceMessage;
            $scope.enumTypeFixations = enumFactory.TypeFixations;
            $scope.autoFixationContractBase = false;
            $scope.enumRoles = enumFactory.Roles;
            $scope.withConditions = true;
            $scope.idContractBase = $routeParams.idContractBase;
            $scope.isGlobalFixation = Number($scope.idTypeFixation) === $scope.enumTypeFixations.GlobalPrice.IdTypeFixation;
            $scope.isFutureFixation = Number($scope.idTypeFixation) === $scope.enumTypeFixations.Future.IdTypeFixation;
            $scope.isModuleAlertesSmsActive = $rootScope.Modules.ModuleAlertesSms.Active;
            $scope.enumRoles = enumFactory.Roles;
            $scope.effortPriceTcEnabled = false;
            $scope.tcEffortInputIsDelta = parametersFactory.Parameters.TcEffortInputMode.Value == 'DELTA';

            $scope.enumTypeEffortTc = enumFactory.TypeEffortTc;
            $scope.quantity = $routeParams.quantity;
            $scope.targetPrice = $routeParams.targetPrice;
            $scope.enumFactory = enumFactory;
            $scope.selectedConnection = {
                Connection: {}
            };
            $scope.signatureYouSign = {};
            $scope.showListChooseContact = false;
            $scope.moduleYousignIsActive = $rootScope.ModuleSignatureElectroniqueYouSign.Active;
            $scope.IsUneal = $rootScope.paramNameCoop === enumFactory.Coops.Uneal ? true : false;
            $scope.listConnection = [];
            $scope.showConfirmButton = true;
            $scope.delayedSigning = parametersFactory.Parameters.DelayedSigning.Value === "1";
            $scope.contractValidationDisabled = true;
            $scope.parambActivePriceOptimizer = parametersFactory.Parameters.bActivePriceOptimizer.Value === "1";
            $scope.signingEnabled = ($scope.moduleYousignIsActive || $rootScope.ModuleElectronicSignature.Active) && $rootScope.activateSignature && (!($rootScope.paramDelayedSigning == '1' && $rootScope.User.IdRole === $scope.enumRoles.TC) || ($rootScope.User.IdRole === $scope.enumRoles.TC && $rootScope.isTcSigningForUser));
            $scope.showCheckedCondition = !($rootScope.paramDelayedSigning == '1' && $rootScope.User.IdRole == $scope.enumRoles.TC) || ($rootScope.User.IdRole === $scope.enumRoles.TC && $rootScope.isTcSigningForUser);
            $scope.showButtonSigningForTc = $rootScope.User.IdRole == $scope.enumRoles.TC && $rootScope.paramDelayedSigning && $rootScope.activateSignature && $rootScope.paramIsTCAuthorizedToSignInModeDelayed && !$rootScope.goToDelayedSigning;

            $scope.user = currentUserFactory.User;
            $scope.mobileNumberToSendSms = $rootScope.selectedClientFromTc ? $rootScope.selectedClientFromTc.MobileNumber : currentUserFactory.Connection ? currentUserFactory.Connection.MobileNumber : $scope.user.MobileNumber;
            $scope.bUseDepositVolume = (parametersFactory.Parameters.bActiveDepositVolume.Value === "1" && !!parametersFactory.Parameters.IdsOperationForDepositVolume);
            $scope.paramAuthorizeTargetPriceUnderOfferPrice = parametersFactory.Parameters.bAuthorizeTargetPriceUnderOfferPrice.Value === "1";

            $scope.targetPriceNotification = {
                IsMail: true
            };
            $scope.termsAndConditions = {
                isChecked: false
            };
            $scope.security = {
                Code: ""
            };
            $scope.counter = {
                start: false,
                pause: false
            };
            $scope.coverContract = {
                isChecked: true
            };
            if ($rootScope.User.IdRole === $scope.enumRoles.TC && $rootScope.User.EffortPriceTcEnabled && !$rootScope.isTcSigningForUser) { //Si on est un TC et qu'il y a des efforts de prix possible alors le TC à le droit de saisir un effort
                $scope.effortPriceTcEnabled = true;
            }

            //Prise en compte des modifications des parametres par l'Admin
            parametersService.GetParameters(function (dataParam, statusParam) {
                if (statusParam !== undefined && statusParam !== null) {
                    if (statusParam === 400) {
                        $scope.loginErrorMessage = dataParam[0].errors[0].Message;
                    }
                } else {
                    parametersFactory.Parameters = dataParam;
                    $scope.paramCanChooseTargetPriceMessage = parametersFactory.Parameters.CanChooseTargetPriceMessage.Value === "1";
                    $scope.paramDisplaySurfaceYield = parametersFactory.Parameters.DisplaySurfaceYield.Value === "1";

                    if (!parametersFactory.Parameters.FileTermCondition.Value)
                        $scope.showUrlSellConditionAttachment = false;
                    else {
                        $scope.urlSellConditionAttachment = configurationFactory.Url['Api'] + 'Upload/GetAttachment?p=' + parametersFactory.Parameters.FileTermCondition.Value;
                        $scope.showUrlSellConditionAttachment = true;
                    }

                    if (!parametersFactory.Parameters.FileTermeUse.Value)
                        $scope.showUrlUtilConditionAttachment = false;
                    else {
                        $scope.urlUtilConditionAttachment = configurationFactory.Url['Api'] + 'Upload/GetAttachment?p=' + parametersFactory.Parameters.FileTermeUse.Value;
                        $scope.showUrlUtilConditionAttachment = true;
                    }
                }
            });
            if ($rootScope.goToDelayedSigning && $rootScope.idContractForDelayedSigning) {
                $scope.isGlobalFixation = true;
                $rootScope.goToDelayedSigning = false;
                $rootScope.isTcSigningForUser = false;
                contractsService.GetContractForDelayedSigning($rootScope.idContractForDelayedSigning, function (data, status) {
                    if (status !== null) {
                        if (status === 400) {
                            $scope.errorMessage = data[0].errors[0].Message;
                        }
                    } else {
                        $scope.resumeOffer = data;
                        $scope.ProductQuantityLeftDepositToSellFunction();
                        if (data.LabelVariety)
                            $scope.selectedVariety = {
                                Label: data.LabelVariety
                            };
                        $rootScope.hasSmsCodeSigningAlreadySend = data.HasSmsCodeSigningAlreadySend;
                        $scope.isSell = data.IdTypeOrder === enumFactory.TypeOrders.Vente.IdTypeOrder;
                        $scope.isObjective = data.IdTypeOrder === enumFactory.TypeOrders.Prixobjectif.IdTypeOrder;
                        goToStep("validation");
                    }
                });
            } else if ($rootScope.idOfferSelected) {
                // Dans le cas ou il ne sagit pas d'un contrat en attente de signature, on passe nos deux params à false et null
                $rootScope.idContractForDelayedSigning = null;
                $rootScope.goToDelayedSigning = false;
                $rootScope.isTcSigningForUser = false;
                $rootScope.hasSmsCodeSigningAlreadySend = false;
                // Récupération de l'offre à souscrire
                offerService.GetOfferByIdAndIdExpiry($rootScope.idOfferSelected, $rootScope.idExpirySelected, $scope.idTypeFixation, function (data, status) {
                    if (status !== null) {
                        if (status === 400)
                            $scope.errorMessage = data[0].errors[0].Message;
                    } else {
                        $scope.resumeOffer = data;
                        $scope.autoFixationContractBase = data.IdTypeFixationBase == enumFactory.TypeFixationBases.Automatic;
                        $scope.ProductQuantityLeftDepositToSellFunction();
                        $scope.contractValidationDisabled = data.ContractValidationDisabled;
                        $scope.signingEnabled = $scope.signingEnabled && $scope.contractValidationDisabled && $scope.resumeOffer.ContractSigningEnabled;
                        if ($scope.targetPrice) {
                            if ($scope.isGlobalFixation)
                                $scope.resumeOffer.targetPriceCurrent = $filter('roundByDecimalAndConverter')($scope.targetPrice, $scope.resumeOffer.DecimalCurrencyUnitFinal, $scope.resumeOffer.ConverterCoeffValue);// arrondir le targetPriceCurrent a la décimal des composantes
                            else
                                $scope.resumeOffer.targetPriceCurrent = $scope.targetPrice;
                        }
                        if ($scope.quantity) {
                            $scope.resumeOffer.tonnageCurrent = Number($scope.quantity);
                        }
                        $scope.paramObligationFixingContractBaseInFirstComponent = $scope.resumeOffer.IdTypeFixationBase === enumFactory.TypeFixationBases.Obligatoire;
                        $scope.isSubscribeTargetPriceWithBaseAcquisition = $scope.isGlobalFixation && $rootScope.idTypeOrderSelected == enumFactory.TypeOrders.Prixobjectif.IdTypeOrder && $scope.resumeOffer.TargetPriceWithBaseAcquisition;
                        ManageStepCompatibleBases();
                    }
                });
            } else
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_IdentifierOfOfferIsMissing.Content;
        };

        var ManageStepCompatibleBases = function () {
            if ($scope.isFutureFixation && !$scope.idContractBase) {
                GetCompatibleBases();
            } else {
                if ($scope.idContractBase) {
                    SetContractBaseByIdContractBase($scope.idContractBase);
                }
                ManageStepCompatibleCommitments();
            }
        };

        var GetCompatibleBases = function () {
            if (!$scope.idContractCommitment)
                $scope.idContractCommitment = "";

            contractBaseService.GetCompatibleContractBasesWithFilter($rootScope.idExpirySelected, $scope.idContractCommitment, $scope.paramsCompatibleBases.maxContracts, $scope.paramsCompatibleBases.pageNo, $scope.paramsCompatibleBases.sortKey, $scope.paramsCompatibleBases.reverse, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                    }
                } else {
                    if (data.ListVm.length < 1)
                        $scope.SelectContractBase(null);
                    else {
                        goToStep("compatibleBases");
                        $scope.lstCompatibleBases = data.ListVm;
                        $scope.paramsCompatibleBases.total = data.Total;
                        $scope.tableCompatibleBasesDataVisible = colonnesContractBaseCompatibles.data.DataVisible.split(',');
                        $scope.tableCompatibleBasesLibelle = colonnesContractBaseCompatibles.data.ColumnName.split(',');
                    }
                }
            });
        };

        var ManageStepCompatibleCommitments = function () {
            if ($scope.resumeOffer.IdTypeOfferCommitment === $scope.enumTypeOfferCommitment.EngagementFacultatif && !$scope.idContractCommitment) {
                goToStep("compatibleCommitments");
                GetCompatibleCommitments();
            } else {
                // Récupération de l'engagement du contrat
                if ($scope.idContractCommitment) {
                    SetContractCommitmentByIdContractCommitment($scope.idContractCommitment);
                }
                ManageStepContractualisation();
            }
        };

        var GetCompatibleCommitments = function () {
            contractsService.GetCompatibleCommitmentsWithFilter($rootScope.idExpirySelected, $scope.paramsCompatibleCommitments.maxContracts, $scope.paramsCompatibleCommitments.pageNo, $scope.paramsCompatibleCommitments.sortKey, $scope.paramsCompatibleCommitments.reverse, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                    }
                } else {
                    $scope.lstCompatibleCommitments = data.ListVm;
                    $scope.paramsCompatibleCommitments.total = data.Total;
                    $scope.tableCompatibleCommitmentsDataVisible = colonnesContractTypeCommitmentAndProduct.data.DataVisible.split(',');
                    $scope.tableCompatibleCommitmentsLibelle = colonnesContractTypeCommitmentAndProduct.data.ColumnName.split(',');
                }
            });
        };

        var ManageStepContractualisation = function () {
            goToStep("contractualisation");
            $scope.withConditions = (!$scope.idContractCommitment || ($scope.contractualisationWithCommitment && !$scope.contractualisationWithCommitment.HasContractConditions)) && (!$scope.idContractBase || ($scope.contractualisationWithBase && !$scope.contractualisationWithBase.HasContractConditions));

            //On vérifie si on doit préremplir ou pas le champ tonnage
            if ($rootScope.parambPreFillTonnage) {
                $scope.resumeOffer.tonnageCurrent = Number(GetTonnageMaxLimit());
            }

            // Initialisation du compteur pour le delai de contractualisation
            $scope.timeToContracting = new Date();

            if (!$rootScope.idContractForDelayedSigning)
                contractingDelayService.InitialisationContractingDelay($scope.counter);

            // Vérification du type de prix
            typeOrdersService.CheckIsValidTypeOrder($rootScope.idTypeOrderSelected, function (res) {
                $scope.isSell = res.isSell;
                $scope.isObjective = res.isObjective;
                $scope.resumeOffer.idTypeOrder = res.idTypeOrder;
                $scope.errorMessage = res.errorMessage;
            });


            if ($scope.isGlobalFixation || $scope.contractualisationWithBase)
                if ($scope.resumeOffer.NetPrice)
                    $scope.sellPriceToDisplay = $scope.resumeOffer.GlobalPrice;
                else
                    $scope.sellPriceToDisplay = $scope.resumeOffer.GlobalPriceWithCoeffConverted;
            else
                $scope.sellPriceToDisplay = ($scope.resumeOffer.FinalPrice ? $scope.resumeOffer.FinalPrice : $scope.resumeOffer.RefPrice);

            $scope.sellPriceCurrencyToDisplay = ($scope.isGlobalFixation || $scope.contractualisationWithBase) ? $scope.resumeOffer.CurrencyUnitFinal : $scope.resumeOffer.CurrencyUnitForComponents;

            if ($scope.effortPriceTcEnabled) {
                $scope.resumeOffer.EffortPriceTc = $scope.sellPriceToDisplay;
                $scope.resumeOffer.DeltaEffortPriceTc = 0;
            }

            // Récupération des conditions particulères
            GetCondParts();
        };

        var SetContractCommitmentByIdContractCommitment = function (idContractCommitment) {
            var basePrice = "";
            var targetBasePrice = "";

            if ($scope.contractualisationWithBase) {
                if ($scope.contractualisationWithBase.TargetBasePrice)
                    targetBasePrice = $scope.contractualisationWithBase.TargetBasePrice;
                else if ($scope.contractualisationWithBase.OfferBasePrice)
                    basePrice = $scope.contractualisationWithBase.OfferBasePrice;
            }
            else if ($scope.isGlobalFixation)
                basePrice = $scope.resumeOffer.BasePrice;

            contractsService.GetContractCommitmentByIdContract(idContractCommitment, $scope.resumeOffer.IdOffer, basePrice, targetBasePrice, $scope.resumeOffer.MarketPrice, "", function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                    }
                } else {
                    $scope.contractualisationWithCommitment = data;

                    if ($scope.contractualisationWithCommitment.HasContractConditions && (!$scope.contractualisationWithBase || ($scope.contractualisationWithBase && !$scope.contractualisationWithBase.HasContractConditions))) {
                        $scope.resumeOffer.TotalConditionsValue = $scope.contractualisationWithCommitment.TotalConditionsValue;
                        $scope.resumeOffer.FinalPrice = $scope.contractualisationWithCommitment.FinalPrice;
                        $scope.resumeOffer.FinalPriceWithCoeffConverted = $filter('roundByDecimalAndConverter')($scope.resumeOffer.FinalPrice, $scope.resumeOffer.DecimalCurrencyUnitFinal, $scope.resumeOffer.ConverterCoeffValue);// conversion pour l'affichage
                    }

                    if ($scope.idContractCommitment) {
                        ManageStepContractualisation();
                    }
                }
            });
        };

        var SetContractBaseByIdContractBase = function (idContractBase) {
            contractBaseService.GetContractBaseByIdContractBase(idContractBase, $scope.resumeOffer.MarketPrice, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                    }
                } else {
                    $scope.contractualisationWithBase = data;
                    $scope.resumeOffer.BasePrice = $scope.contractualisationWithBase.BasePrice;
                    $scope.resumeOffer.TotalConditionsValue = $scope.contractualisationWithBase.TotalConditionsValue;
                    $scope.resumeOffer.FinalPrice = $scope.contractualisationWithBase.FinalPrice;
                    $scope.resumeOffer.RefPrice = $scope.contractualisationWithBase.MarketPrice;
                    $scope.resumeOffer.FinalPriceWithCoeffConverted = $filter('roundByDecimalAndConverter')($scope.resumeOffer.FinalPrice, $scope.resumeOffer.DecimalCurrencyUnitFinal, $scope.resumeOffer.ConverterCoeffValue);

                    $scope.idContractCommitment = $scope.contractualisationWithBase.IdContractCommitment;
                    if ($scope.idContractCommitment) {
                        SetContractCommitmentByIdContractCommitment($scope.idContractCommitment);
                        ManageStepContractualisation();
                    }
                    else {
                        ManageStepCompatibleCommitments();
                    }
                }
            });
        };

        var GetCondParts = function () {
            if ($scope.parambActivePriceOptimizer) {
                conditionsService.GetBestPath($scope.resumeOffer.IdOffer, function (bestPath) {
                    conditionsService.GetCondParts($scope.resumeOffer.IdOffer, $scope.resumeOffer.IdTree, $scope.idContractCommitment, $scope.contractualisationWithCommitment, bestPath, $rootScope.idExpirySelected, function (res) {
                        $scope.errorMessage = res.errorMessage;
                        $scope.conditionParticulars = res.conditionParticulars;
                    });
                });
            }
            else {
                conditionsService.GetCondParts($scope.resumeOffer.IdOffer, $scope.resumeOffer.IdTree, $scope.idContractCommitment, $scope.contractualisationWithCommitment, '', $rootScope.idExpirySelected, function (res) {
                    $scope.errorMessage = res.errorMessage;
                    $scope.conditionParticulars = res.conditionParticulars;
                });
            }
        };

        var SaveNewContrat = function (newContractVm) {
            contractsService.SaveNewContract(newContractVm, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isToLate = true;
                        $scope.isConfirmed = false;
                        $scope.counter.pause = true;
                    }
                } else {
                    $scope.idContract = data.idContract;
                    $scope.hashContract = data.hashContract;
                    $scope.counter = {};
                    $scope.counter.start = false;
                    goToStep("confirmation");
                }
            });
        };

        var UpdateContract = function (idContract) {
            contractsService.SignContractForDelayedSigning(idContract, $scope.selectedConnection.Connection.IdConnection, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isToLate = true;
                        $scope.isConfirmed = false;
                        $scope.counter.pause = true;
                    }
                } else {
                    $scope.isConfirmed = false; 
                    $scope.idContract = data.idContract;
                    $scope.hashContract = data.hashContract;
                    $scope.counter = {};
                    $scope.counter.start = false;
                    goToStep("confirmation");
                }
            });
        };

        var CheckIsValidTonnageCurrent = function (tonnageMaxLimit, tonnageMaxCropProduct) {
            var qtyCommitmentToFix = '';
            if ($scope.idContractCommitment) {
                if ($scope.contractualisationWithBase)
                    qtyCommitmentToFix = $scope.contractualisationWithBase.QuantityLeft;
                else
                    qtyCommitmentToFix = $scope.contractualisationWithCommitment.TonnageToFix;
            }
            offerService.CheckIsValidTonnageCurrent($scope.resumeOffer.tonnageCurrent, $scope.resumeOffer.TonnageMin, $scope.resumeOffer.TonnageMax, $scope.resumeOffer.QuantityLeft, $scope.resumeOffer.Quotity, qtyCommitmentToFix, $scope.resumeOffer.idTypeOrder, $scope.resumeOffer.IdTypeCover, tonnageMaxLimit, tonnageMaxCropProduct, function (error) {
                $scope.errorTonnageMessage = error.substring(1, error.length - 1); // Permet de supprimer les guillemets en début et fin de message
                if (tonnageMaxCropProduct || tonnageMaxCropProduct === 0) { //demande ducroquet deuxieme check, on laisse passer //On est obligé d'ajouter || tonnageMaxCropProduct === 0 car sinon dans le cas où il reste 0 alors on ne rentre pas dans le if
                    $scope.resumeOffer.MaxCropProductTonnage = null;
                }
                if ($scope.errorTonnageMessage){
                    $scope.isConfirmed = false;
                    return;
                }
                if ($scope.effortPriceTcEnabled) {

                    var isGlobalPriceOrSecondComponent = false;
                    var coeffValue = "";

                    if ($scope.contractualisationWithBase != null || $scope.resumeOffer.IdTypeFixation == 3) // si fixation 2eme composante ou globalFixation, on envoie le coeff value
                    {
                        coeffValue = $scope.resumeOffer.ConverterCoeffValue;
                        isGlobalPriceOrSecondComponent = true;
                    }
                    else
                        coeffValue = ""; //sinon, on envoie une chaine vide

                    var totalEffort = $scope.resumeOffer.EffortPriceTc + $scope.resumeOffer.DeltaEffortPriceTc;

                    userService.CheckEffortTc(totalEffort, $scope.sellPriceToDisplay, $scope.enumTypeEffortTc.Price, coeffValue, isGlobalPriceOrSecondComponent, $scope.resumeOffer.DecimalCurrencyUnitForComponents, function (data) {
                        if (data.ErrorMessage) {
                            $scope.errorEffortPriceTcMessage = data.ErrorMessage;
                            $scope.isConfirmed = false;
                            return;
                        }

                        $scope.effortPriceTc = data.EffortTc;
                        $scope.marketPriceEffortPriceTc = $scope.resumeOffer.MarketPrice + $scope.effortPriceTc + $scope.totalCondPart;
                        $scope.refPriceEffortPriceTc = $scope.resumeOffer.RefPrice + $scope.effortPriceTc + $scope.totalCondPart;
                        $scope.finalPriceEffortPriceTc = parseFloat($scope.resumeOffer.FinalPrice) + $scope.effortPriceTc + $scope.totalCondPart;//On fait un parsefloat car il considère comme une chaine de caractère et au lieu de faire 16+4 et afficher 20 il affiche 164 si on ne fait pas de parsefloat
                        $scope.finalPriceEffortPriceTcWithCoeffConverted = $filter('roundByDecimalAndConverter')($scope.finalPriceEffortPriceTc, $scope.resumeOffer.DecimalCurrencyUnitFinal, $scope.resumeOffer.ConverterCoeffValue);

                        $scope.isConfirmed = false;
                        goToStep("validation");
                    });
                }
                else {
                    $scope.isConfirmed = false;
                    goToStep("validation");
                }

            });
        };

        $scope.GetDataCompatibleBases = function (pageNo) {
            $scope.paramsCompatibleBases.pageNo = pageNo;
            GetCompatibleBases();
        };

        $scope.GetDataCompatibleCommitments = function (pageNo) {
            $scope.paramsCompatibleCommitments.pageNo = pageNo;
            GetCompatibleCommitments();
        };

        $scope.SelectContractBase = function (idContractBase) {
            $scope.idContractBase = idContractBase;
            if ($scope.idContractBase) {
                SetContractBaseByIdContractBase($scope.idContractBase);
            }
            else {
                ManageStepCompatibleCommitments();
            }
        };

        $scope.SelectContractCommitment = function (idContractCommitment) {

            $scope.idContractCommitment = idContractCommitment;
            if ($scope.idContractCommitment) {
                SetContractCommitmentByIdContractCommitment($scope.idContractCommitment);
            }
            ManageStepContractualisation();
        };

        var GetTonnageMaxLimit = function () {
            var tonnageMaxLimit = null;
            if ($scope.contractualisationWithCommitment && $scope.contractualisationWithBase && !$scope.contractualisationWithBase.IdContractCommitment)
                tonnageMaxLimit = Math.min($scope.contractualisationWithCommitment.TonnageToFix, $scope.contractualisationWithBase.QuantityLeft);
            else if ($scope.contractualisationWithBase)
                tonnageMaxLimit = $scope.contractualisationWithBase.QuantityLeft;
            else if ($scope.contractualisationWithCommitment)
                tonnageMaxLimit = $scope.contractualisationWithCommitment.TonnageToFix;

            return tonnageMaxLimit;
        };

        $scope.Validate = function () {
            $scope.errorMessage = "";
            $scope.errorTonnageMessage = "";
            $scope.errorPriceMessage = "";
            $scope.errorEffortPriceTcMessage = "";
            $rootScope.lastOfferSelected = $scope.resumeOffer.IdOffer;
            $scope.isConfirmed = true;

            // Vérification des conditions particulières
            $scope.resumeOffer.LstGroupConditionsSelectedValues = [];
            if ($scope.withConditions) {
                $scope.errorMessage = conditionsService.CheckAndSetGroupConditions($scope.conditionParticulars, $scope.resumeOffer.LstGroupConditionsSelectedValues);
                if ($scope.errorMessage){
                    $scope.isConfirmed = false;
                    return;
                }  
            }
       
            // Vérification de la variété
            if ($scope.resumeOffer.LstVarieties.length > 0 && !$scope.resumeOffer.IdVariety && (!$scope.idContractCommitment || ($scope.idContractCommitment && !$scope.contractualisationWithCommitment.HasVariety))) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseEnterAVariety.Content;
                $scope.isConfirmed = false;
                return;
            }
            else if ($scope.resumeOffer.LstVarieties.length > 0)
                $scope.selectedVariety = $filter('filter')($scope.resumeOffer.LstVarieties, { IdVariety: $scope.resumeOffer.IdVariety }, true)[0];

            // Vérification du tonnage du contrat
            var tonnageMaxLimit = GetTonnageMaxLimit();

            // Vérification du prix objectif
            var targetPriceToCompare = null;
            if ($scope.isGlobalFixation && !$scope.isSubscribeTargetPriceWithBaseAcquisition) {
                targetPriceToCompare = $scope.resumeOffer.GlobalPriceWithCoeffConverted;
                $scope.resumeOffer.targetPriceCurrent = $filter('roundByDecimalAndConverter')($scope.resumeOffer.targetPriceCurrent, $scope.resumeOffer.DecimalCurrencyUnitFinal);// arrondir le targetPriceCurrent à la décimal final 
            }
            else {
                targetPriceToCompare = $scope.resumeOffer.RefPrice;
                $scope.resumeOffer.targetPriceCurrent = $filter('roundByDecimalAndConverter')($scope.resumeOffer.targetPriceCurrent, $scope.resumeOffer.DecimalCurrencyUnitForComponents);// arrondir le targetPriceCurrent a la décimal des composantes
            }

            $scope.errorPriceMessage = targetPriceService.CheckPriceAndDateIsValid($scope.resumeOffer, $scope.idContractCommitment, $scope.contractualisationWithCommitment, targetPriceToCompare, $scope.paramAuthorizeTargetPriceUnderOfferPrice);
            if ($scope.errorPriceMessage)
            {
                $scope.isConfirmed = false;
                return;
            }
                

            // Vérification du choix de notification du prix objectif
            $scope.errorPriceMessage = targetPriceService.CheckTargetPriceNotification($scope.resumeOffer, $scope.targetPriceNotification.IsMail, $scope.paramCanChooseTargetPriceMessage, $scope.user);
            if ($scope.errorPriceMessage){
                $scope.isConfirmed = false;
                return;
            }
                
            // Calcul de la somme des conditions particulières séléctionnées
            $scope.totalLstGroupConditionsSelectedValues = conditionsService.GetTotalLstGroupConditionsSelectedValues($scope.resumeOffer.LstGroupConditionsSelectedValues);

            // Calcul du prix final
            $scope.totalCondPart = !!$scope.totalLstGroupConditionsSelectedValues && !$scope.contractualisationWithBase ? $scope.totalLstGroupConditionsSelectedValues : ($scope.resumeOffer.TotalConditionsValue ? $scope.resumeOffer.TotalConditionsValue : 0);
            var refPrice = "";
            if ($scope.isSell)
                refPrice = $scope.resumeOffer.RefPrice;
            else {
                refPrice = $scope.resumeOffer.targetPriceCurrent;
            }
            var basePrice = "";

            if ($scope.isGlobalFixation || $scope.contractualisationWithBase) {
                basePrice = $scope.contractualisationWithBase ? ($scope.contractualisationWithBase.TargetBasePrice ? $scope.contractualisationWithBase.TargetBasePrice : $scope.resumeOffer.BasePrice) : (($scope.isSell || $scope.isSubscribeTargetPriceWithBaseAcquisition) ? $scope.resumeOffer.BasePrice : "");
                var finalPrice = targetPriceService.GetPreviewFinalPrice(basePrice, refPrice, $scope.totalCondPart);
                if ($scope.resumeOffer.NetPrice)
                    $scope.resumeOffer.FinalPriceWithCoeffConverted = $filter('roundByDecimalAndConverter')(($scope.resumeOffer.GlobalPrice + $scope.totalCondPart) ,$scope.resumeOffer.DecimalCurrencyUnitForComponents, $scope.resumeOffer.ConverterCoeffValue);// arrondir le targetPriceCurrent a la décimal des composantes
                else
                $scope.resumeOffer.FinalPriceWithCoeffConverted = $filter('roundByDecimalAndConverter')(finalPrice, $scope.resumeOffer.DecimalCurrencyUnitForComponents, $scope.resumeOffer.ConverterCoeffValue);// arrondir le targetPriceCurrent a la décimal des composantes
            }
            else if($scope.autoFixationContractBase)
                $scope.resumeOffer.FinalPrice = targetPriceService.GetPreviewFinalPrice($scope.resumeOffer.BasePriceWithIncidence, refPrice, $scope.totalCondPart);
            else
                $scope.resumeOffer.FinalPrice = targetPriceService.GetPreviewFinalPrice("", refPrice, $scope.totalCondPart);


            // Vérification de la date de forçage
            if ($rootScope.paramIsTcAuthorizedToForcedDate && $rootScope.User.IdRole === $scope.enumRoles.TC && $scope.isSell && ($scope.isGlobalFixation || $scope.isFutureFixation)) {
                if (!$scope.resumeOffer.DateSubscriptionForcedString) {
                    $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseEnterDateForced.Content;
                    $scope.isConfirmed = false;
                    return;
                }          
                if ($scope.resumeOffer.DateSubscriptionForcedString) {
                    if (!dateService.compareDate1SuperiorDate2(new Date(), dateService.ConvertDateToDateAPI($scope.resumeOffer.DateSubscriptionForcedString))) {
                        $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseEnterAnteriorDateForced.Content;
                        $scope.isConfirmed = false;
                        return;
                    }
                }
            }

            // Vérification du délai de contractualisation
            if (!$rootScope.idContractForDelayedSigning) {
                $scope.errorMessage = contractingDelayService.VerifyContractingDelay($scope.timeToContracting);
                if ($scope.errorMessage) {
                    $scope.isToLate = true;
                    $scope.isConfirmed = false;
                    return;
                }
            }

            CheckIsValidTonnageCurrent(tonnageMaxLimit, $scope.resumeOffer.MaxCropProductTonnage);

        };

        $scope.ValidateConnection = function () {
            if (!$scope.selectedConnection.Connection.IdConnection) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.ChooseContacte_OfferSubscribe_ListEmpty.Content;
                return;
            }
            $scope.showConfirmButton = false;
            $scope.isConfirmed = true;
            $scope.errorMessage = "";
            $scope.mobileNumberToSendSms = $scope.selectedConnection.Connection.MobileNumber;

            if ($rootScope.idContractForDelayedSigning){
                $scope.SendSecurityCode();
                $scope.firstSendSmsMessageDelayedSigning = false;
                $scope.counter.pause = false;
                $scope.isConfirmed = false;
                $scope.security.Code = "";
                $scope.security.CodeSendingAway = false;
                $scope.showConfirmButton = true;
                $scope.showListChooseContact = false;
            }
            else {
                // Création de la vm du contrat
                var newContractVm = getContractVm();

                if ($scope.signingEnabled && $rootScope.ModuleElectronicSignature.Active)
                    checkContractAndSendSmsForWithoutThirdParty(newContractVm);
                else if ($scope.signingEnabled && $scope.moduleYousignIsActive)
                    checkContractAndSendSmsForYouSign(newContractVm);
            }
        };

        $scope.Confirm = function () {
            $scope.isConfirmed = true;
            $scope.errorMessage = "";
            $scope.successMessage = "";
            $scope.counter.pause = true;

            if ($rootScope.idConnectionLogged)
                    $scope.selectedConnection.Connection.IdConnection = $rootScope.idConnectionLogged;

            // Vérification de l'acceptation des condition générale de vente
            if (!$scope.termsAndConditions.isChecked && $scope.showCheckedCondition) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseAcceptGeneralConditionsOfSaleBeforeValidation.Content;
                $scope.isConfirmed = false;
                $scope.counter.pause = false;
                return;
            }
            // Vérification du delai de contractualisation
            if (!$rootScope.idContractForDelayedSigning) {
                $scope.errorMessage = contractingDelayService.VerifyContractingDelay($scope.timeToContracting);
                if ($scope.errorMessage) {
                    $scope.isToLate = true;
                    $scope.isConfirmed = false;
                    return;
                }
            }

            if ($rootScope.idContractForDelayedSigning) {
                if (!$rootScope.hasSmsCodeSigningAlreadySend) 
                {
                    // Gestion des contacts
                    if ($scope.signingEnabled && parametersFactory.Parameters.bUseTableConnections.Value === "1" && !$scope.selectedConnection.Connection.IdConnection)
                        getListConnections();
                    else
                        $scope.SendSecurityCode();
                    $scope.firstSendSmsMessageDelayedSigning = false;
                }
                else
                    $scope.firstSendSmsMessageDelayedSigning = true;
                
                $scope.isConfirmed = false;
                goToStep("signatureContract");
            } else {
                // Création de la vm du contrat
                var newContractVm = getContractVm();

                if (!$scope.contractValidationDisabled)
                    return SaveNewContrat(newContractVm);

                if ($scope.step === "validation") {
                    // Gestion des contacts
                    if ($scope.signingEnabled && parametersFactory.Parameters.bUseTableConnections.Value === "1" && !$scope.selectedConnection.Connection.IdConnection)
                        getListConnections();
                    // Envoi signature YOUSIGN
                    else if ($scope.signingEnabled && $scope.moduleYousignIsActive)
                        checkContractAndSendSmsForYouSign(newContractVm);
                    // Envoi signature SANS TIERS DE CONFIANCE
                    else if ($scope.signingEnabled && $rootScope.ModuleElectronicSignature.Active && $scope.showCheckedCondition)
                        checkContractAndSendSmsForWithoutThirdParty(newContractVm);
                    else
                        SaveNewContrat(newContractVm);
                }
                else if ($scope.step === "signatureContract") {
                    // Vérification du code pour signature SANS TIERS DE CONFIANCE et sauvegarde du contrat
                    if ($scope.signingEnabled && $rootScope.ModuleElectronicSignature.Active)
                        checkSmsCodeAndSaveContractForWithoutThirdParty(newContractVm);
                    // Vérification du code pour signature YOUSIGN et mise à jour du contrat
                    else if ($scope.signingEnabled && $scope.moduleYousignIsActive)
                        checkSmsCodeForYouSign();
                }
            }
        };

        $scope.UpdateContractDelayedSigning = function () {
            $scope.isConfirmed = true;
            $scope.successMessage = "";
            signatureService.CheckSecurityCodeFirmOrCommitment($scope.security.Code, $rootScope.idContractForDelayedSigning, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isConfirmed = false;
                    }
                }
                else {
                    $scope.errorMessage = "";
                    UpdateContract($rootScope.idContractForDelayedSigning);
                }
            });
        };

        $scope.ConditionParticularsOnChange = function (condition) {
            conditionsService.ConditionParticularsOnChange($scope.resumeOffer.IdOffer, condition, '', $rootScope.idExpirySelected);
        };

        $scope.AutocompletConditionParticularsOnChange = function (selectedValue, condition) {
            condition.selectedValue = selectedValue.originalObject.IdTreeCondition;
            $scope.conditionSelected = condition;
            conditionsService.ConditionParticularsOnChange($scope.resumeOffer.IdOffer, condition, '', $rootScope.idExpirySelected);
        };

        $scope.AutocompleteCondPartSearch = function (str, LstOptions) {
            var matches = [];
            LstOptions.forEach(function (option) {
                if ((option.Label.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0)) {
                    matches.push(option);
                }
            });
            return matches;
        };

        $scope.inputChanged = function (str) {
            if ($scope.conditionSelected) {
                $scope.conditionSelected.LstConditionParticularsChilds = [];
            }
        };

        $scope.SetTonnageCurrent = function () {
            $scope.resumeOffer.tonnageCurrent = $scope.resumeOffer.surface * $scope.resumeOffer.rendement;
        };

        $scope.SendSecurityCode = function () {
            $scope.security.Code = "";

            if ($rootScope.ModuleElectronicSignature.Active && $rootScope.activateSignature) {
                var idConnection = $scope.selectedConnection.Connection.IdConnection ? $scope.selectedConnection.Connection.IdConnection : "";
                signatureService.SendSmsForElectronicSignature($scope.timeToContracting, $rootScope.idContractForDelayedSigning, idConnection, function (res) {
                    $scope.isToLate = res.isToLate;
                    $scope.errorMessage = res.errorMessage;
                    if (!$scope.firstSendSmsMessageDelayedSigning)
                        $scope.successMessage = res.successMessage;
                    else
                        $scope.successMessage = "";
                    $scope.firstSendSmsMessageDelayedSigning = false;
                });
            }
            else if ($scope.moduleYousignIsActive && $rootScope.activateSignature) {
                signatureService.ResendCodeYouSign($scope.signatureYouSign.Signature.MemberId, function (data, status) {
                    if (status !== null) {
                        if (status === 400) {
                            $scope.errorMessage = data[0].errors[0].Message;
                            $scope.isConfirmed = false;
                        }
                    }
                    else {
                        if (data) {
                            $scope.newAuthenticationId = data.AuthenticationId;
                            $scope.errorMessage = "";
                            $scope.successMessage = $rootScope.editableObjectsFactory.EditableObjects.Success_OfferSubscribe_CodeResend.Content;
                        }
                    }
                });
            }
        };

        $scope.Cancel = function () {
            $location.path("user/offers/view/" + enumFactory.TypeOffer.Ferme);
        };

        $scope.ChangeVariety = function () {
            if ($scope.bUseDepositVolume) {
                $scope.selectedVariety = $filter('filter')($scope.resumeOffer.LstVarieties, { IdVariety: $scope.resumeOffer.IdVariety }, true)[0];
                $scope.TextQtyLeftDepositToSell = $rootScope.editableObjectsFactory.EditableObjects.User_Subscribe_TextQtyLeftDepositToSell.Content.replace("[QtyLeftDeposit]", $scope.selectedVariety.QuantityLeftDepositToSell ? $scope.selectedVariety.QuantityLeftDepositToSell : (0));
            }           
        };
        $scope.ProductQuantityLeftDepositToSellFunction = function () {
            if ($scope.bUseDepositVolume && $scope.resumeOffer.LstVarieties.length === 0)
            {
                $scope.TextQtyLeftDepositToSell = $rootScope.editableObjectsFactory.EditableObjects.User_Subscribe_TextQtyLeftDepositToSell.Content.replace("[QtyLeftDeposit]", $scope.resumeOffer.ProductQuantityLeftDepositToSell ? $scope.resumeOffer.ProductQuantityLeftDepositToSell : 0);
            } 
        };

        $scope.GoToSubscribeDelayedSigning = function () {
          $rootScope.goToDelayedSigning = true;
          $rootScope.idContractForDelayedSigning = $scope.idContract;         
          $rootScope.isTcSigningForUser = true;
          $location.path("/user/subscribe/contractFirm/");
        };

        $rootScope.onRefresh = function () {
            init();
        };

        function goToStep(step) {
            $scope.step = step;
            moveVehicle();
        }

        function getContractVm() {
            return {
                IdContractState: 1,
                IdTypeContract: enumFactory.TypeOffer.Ferme,
                IdTypeCommitment: $rootScope.idTypeCommitmentSelected,
                IdTypeOrder: $scope.resumeOffer.idTypeOrder,
                IdTypeCover: $scope.resumeOffer.IdTypeCover,
                IdOffer: $scope.idOfferSelected,
                IdCrop: $scope.resumeOffer.IdCrop,
                IdProduct: $scope.resumeOffer.IdProduct,
                Quantity: $scope.resumeOffer.tonnageCurrent,
                Price: $scope.isGlobalFixation && $scope.resumeOffer.idTypeOrder === enumFactory.TypeOrders.Vente.IdTypeOrder ? $scope.resumeOffer.GlobalPrice : null,
                OfferPrice: $scope.resumeOffer.GlobalPrice,
                TargetPrice: $scope.resumeOffer.targetPriceCurrent ? $scope.resumeOffer.targetPriceCurrent : null,
                IdVariety: $scope.resumeOffer.IdVariety,
                Base: $scope.isGlobalFixation ? $scope.resumeOffer.BasePrice : null,
                LstTreeUserGroupConditionSelectedVms: $scope.resumeOffer.LstGroupConditionsSelectedValues,
                DateEndTargetPrice: $scope.resumeOffer.DateEndTargetPriceUser,
                IdOfferExpiry: $scope.resumeOffer.IdOfferExpiry,
                IdContractCommitment: $scope.idContractCommitment,
                Surface: $scope.resumeOffer.surface,
                SurfaceYield: $scope.resumeOffer.rendement,
                IdTypeTargetPriceMessage: $scope.targetPriceNotification.IsMail ? $scope.enumTypeTargetPriceMessage.Mail : $scope.enumTypeTargetPriceMessage.Sms,
                IdTypeFixation: $scope.idTypeFixation,
                IdContractBase: $scope.idContractBase,
                MarketPrice: $scope.resumeOffer.MarketPrice,
                CoefficientChange: $scope.resumeOffer.ConverterCoeffValue, // Envoi du coefficient permettant de convertir le prix à la Devise/Unité des composantes.
                DecimalCurrencyUnitForComponents: $scope.resumeOffer.DecimalCurrencyUnitForComponents, // Envoi de la décimal pour arrondir le prix à la décimal des composantes.
                EffortPriceTc: $scope.effortPriceTcEnabled ? $scope.effortPriceTc : null,
                DisableContractCoverByTc: $scope.resumeOffer.ShowCheckBoxCoverContract ? !$scope.coverContract.isChecked : null,
                PaymentDateString: $rootScope.User.IsSuperTc && $rootScope.paramNameCoop === enumFactory.Coops.Ducroquet ? $scope.resumeOffer.PaymentDateString : null,
                FreeText: $scope.resumeOffer.FreeText,
                DateSubscriptionForcedString: $scope.resumeOffer.DateSubscriptionForcedString,
                IdConnection: $scope.selectedConnection.Connection.IdConnection
            };
        }

        function checkContractAndSendSmsForWithoutThirdParty(newContractVm) {
            signatureService.CheckAndSendSmsForElectronicSignature(newContractVm, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isConfirmed = false;
                    }
                }
                else {
                    $scope.counter.pause = false;
                    $scope.isConfirmed = false;
                    $scope.security.Code = "";
                    $scope.security.CodeSendingAway = false;
                    $scope.showConfirmButton = true;
                    $scope.showListChooseContact = false;
                    goToStep("signatureContract");
                }
            });
        }

        function checkContractAndSendSmsForYouSign(newContractVm) {
            contractsService.SaveNewContractAndSendSmsYouSign(newContractVm, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isToLate = true;
                        $scope.isConfirmed = false;
                        $scope.counter.pause = true;
                    }
                } else {
                    $scope.isConfirmed = true;
                    $scope.hashContract = data.HashContract;   
                    $scope.idContract = data.IdContract;
                    if (data) {
                        if (data.CanAvoidSigning) {
                            $scope.showConfirmButton = true;                                   
                            $scope.counter = {};
                            $scope.counter.start = false;
                            goToStep("confirmation");
                        }
                        else {
                            $scope.showConfirmButton = true;
                            $scope.showListChooseContact = false;
                            $scope.signatureYouSign = data;
                            $scope.isConfirmed = false;
                            goToStep("signatureContract");
                        }
                    }
                }
            });
        }

        function getListConnections() {
            signatureService.GetListConnection($rootScope.selectedClientFromTcForSubscribe.IdUser, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isConfirmed = false;
                    }
                }
                else {
                    $scope.listConnection = data;
                    if ($scope.listConnection.length === 0)
                        $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.ChooseContacte_OfferSubscribe_ListEmpty_NoBinding_Contacte.Content;
                    else if ($scope.listConnection.length === 1)
                    {
                        $scope.selectedConnection.Connection.IdConnection = $scope.listConnection[0].IdConnection;
                        $scope.ValidateConnection();
                        return;
                    }
                    $scope.isConfirmed = false;
                    $scope.showListChooseContact = true;
                    goToStep("signatureContract");
                }
            });
        }

        function checkSmsCodeAndSaveContractForWithoutThirdParty(newContractVm) {
            var idContract = "";
            if ($scope.idContract)
                idContract = $scope.idContract;

            signatureService.CheckSecurityCodeFirmOrCommitment($scope.security.Code, idContract, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isConfirmed = false;
                    }
                }
                else {
                    newContractVm.IsSignedWithoutThirdParty = true;
                    SaveNewContrat(newContractVm);
                }
            });
        }

        function checkSmsCodeForYouSign() {
            $scope.isConfirmed = true;
            $scope.showConfirmButton = false;
            var idConnection = $scope.selectedConnection.Connection.IdConnection ? $scope.selectedConnection.Connection.IdConnection : "";
            signatureService.SignatureWithCodeYouSign($scope.signatureYouSign.Signature.FilesIds[0], $scope.newAuthenticationId ? $scope.newAuthenticationId : $scope.signatureYouSign.Signature.AuthenticationId, $scope.security.Code, idConnection, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isConfirmed = false;
                    }
                }
                else {
                    if (data && (data === "true" || data === "1")) {
                        $scope.errorMessage = "";
                        goToStep("confirmation");
                    }
                    else {
                        $scope.successMessage = "";
                        $scope.showConfirmButton = true;
                        $scope.isConfirmed = false;
                        if (!$scope.security.Code)
                            $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseEnterCodeReceivedBySms.Content;
                        else
                            $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_CodeEnteredNotCorrespondToCodeSended.Content;
                    }
                }
            });
        }

        init();
    }]);