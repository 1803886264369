AgrimarketControllers.controller('contractsFirmMonitoringController', ['$scope', '$rootScope', '$routeParams', '$filter', '$location', 'configurationFactory', 'contractsService',
    'cropsService', 'athenaOrdersService', '$sce', 'parametersFactory', 'enumFactory', 'colonnesValidatedNotCover', 'colonnesValidatedCovered',
    'colonnesValidatedPendingCover', 'colonnesCanceled', 'colonnesPricedNotCover', 'colonnesPricedPendingCover', 'colonnesPendingNotCover', 'colonnesPendingCovered',
    'colonnesPendingPendingCover', 'colonnesToValidated' ,'columnsDelayedSigning', 'conditionsService',
    function ($scope, $rootScope, $routeParams, $filter, $location, configurationFactory, contractsService, cropsService, athenaOrdersService, $sce, parametersFactory, enumFactory,
        colonnesValidatedNotCover, colonnesValidatedCovered, colonnesValidatedPendingCover, colonnesCanceled, colonnesPricedNotCover, colonnesPricedPendingCover,
        colonnesPendingNotCover, colonnesPendingCovered, colonnesPendingPendingCover, colonnesToValidated, columnsDelayedSigning, conditionsService) {

        var idTabContracts;

        var Init = function () {
            // Contracts validés non couverts
            $scope.tableValidatedContractsNotCoverDataVisible = colonnesValidatedNotCover.data.DataVisible.split(',');
            $scope.tableValidatedContractsNotCoverLibelle = colonnesValidatedNotCover.data.ColumnName.split(',');

            // Contrats validés couverts
            $scope.tableValidatedContractsCoveredDataVisible = colonnesValidatedCovered.data.DataVisible.split(',');
            $scope.tableValidatedContractsCoveredLibelle = colonnesValidatedCovered.data.ColumnName.split(',');

            // Contrats validés en cours de couverture
            $scope.tableValidatedContractsPendingCoverDataVisible = colonnesValidatedPendingCover.data.DataVisible.split(',');
            $scope.tableValidatedContractsPendingCoverLibelle = colonnesValidatedPendingCover.data.ColumnName.split(',');

            // Contracts en attente non couverts
            $scope.tablePendingContractsNotCoverDataVisible = colonnesPendingNotCover.data.DataVisible.split(',');
            $scope.tablePendingContractsNotCoverLibelle = colonnesPendingNotCover.data.ColumnName.split(',');

            // Contrats en attente couverts
            $scope.tablePendingContractsCoveredDataVisible = colonnesPendingCovered.data.DataVisible.split(',');
            $scope.tablePendingContractsCoveredLibelle = colonnesPendingCovered.data.ColumnName.split(',');

            // Contrats en attente en cours de couverture
            $scope.tablePendingContractsPendingCoverDataVisible = colonnesPendingPendingCover.data.DataVisible.split(',');
            $scope.tablePendingContractsPendingCoverLibelle = colonnesPendingPendingCover.data.ColumnName.split(',');

            // Contrats à valider
            $scope.tableToValidatedContractsDataVisible = colonnesToValidated.data.DataVisible.split(',');
            $scope.tableToValidatedContractsLibelle = colonnesToValidated.data.ColumnName.split(',');

            // Contrats prix objectif non couverts
            $scope.tablePricedContractsNotCoverDataVisible = colonnesPricedNotCover.data.DataVisible.split(',');
            $scope.tablePricedContractsNotCoverLibelle = colonnesPricedNotCover.data.ColumnName.split(',');

            // Contrats prix objectif en cours de couverture
            $scope.tablePricedContractsPendingCoverDataVisible = colonnesPricedPendingCover.data.DataVisible.split(',');
            $scope.tablePricedContractsPendingCoverLibelle = colonnesPricedPendingCover.data.ColumnName.split(',');

            // Contrats annulés
            $scope.tableCanceledContractsDataVisible = colonnesCanceled.data.DataVisible.split(',');
            $scope.tableCanceledContractsLibelle = colonnesCanceled.data.ColumnName.split(',');

            // Contracts en attente de signature
            $scope.tableDelayedSigningContractsDataVisible = columnsDelayedSigning.data.DataVisible.split(',');
            $scope.tableDelayedSigningContractsLibelle = columnsDelayedSigning.data.ColumnName.split(',');
          
            // Gestion du trie de l'onglet Validés
            $scope.dataValidated = [];
            $scope.dataValidated[enumFactory.IdStateCover.NotCovered] = { loading: true, reverse: true, sortKey: "IdContract" };
            $scope.dataValidated[enumFactory.IdStateCover.PendingCover] = { loading: false, reverse: true, sortKey: "IdContract" };
            $scope.dataValidated[enumFactory.IdStateCover.Covered] = { loading: true, reverse: true, sortKey: "IdContract" };

            // Gestion du trie de l'onglet en attente
            $scope.dataPending = [];
            $scope.dataPending[enumFactory.IdStateCover.NotCovered] = { loading: false, reverse: true, sortKey: "IdContract" };
            $scope.dataPending[enumFactory.IdStateCover.PendingCover] = { loading: false, reverse: true, sortKey: "IdContract" };
            $scope.dataPending[enumFactory.IdStateCover.Covered] = { loading: false, reverse: true, sortKey: "IdContract" };

            // Gestion du trie de l'onglet A valider
            $scope.dataToValidated = { loading: false, reverse: true, sortKey: "IdContract" };

            // Gestion du trie de l'onglet Prix objectif
            $scope.dataPriced = [];
            $scope.dataPriced[enumFactory.IdStateCover.NotCovered] = { loading: false, reverse: true, sortKey: "IdContract" };
            $scope.dataPriced[enumFactory.IdStateCover.PendingCover] = { loading: false, reverse: true, sortKey: "IdContract" };

            // Gestion du trie de l'onglet Annulés
            $scope.dataCanceled = { loading: false, reverse: true, sortKey: "IdContract" };

            // Gestion du trie de l'onglet En attente de signature
            $scope.dataDelayedSigning = { loading: false, reverse: false, sortKey: "HourLeftDelayedSigning" };

            $scope.editShowValueAutoComplete = false;

            // Pagination onglet Validés
            $scope.total = [];
            $scope.validatedPageNo = [];
            $scope.validatedPageNo[enumFactory.IdStateCover.NotCovered] = 1;
            $scope.validatedPageNo[enumFactory.IdStateCover.PendingCover] = 1;
            $scope.validatedPageNo[enumFactory.IdStateCover.Covered] = 1;

            // Pagination onglet En attente
            $scope.totalPending = [];
            $scope.pendingPageNo = [];
            $scope.pendingPageNo[enumFactory.IdStateCover.NotCovered] = 1;
            $scope.pendingPageNo[enumFactory.IdStateCover.PendingCover] = 1;
            $scope.pendingPageNo[enumFactory.IdStateCover.Covered] = 1;

            // Pagination onglet A valider
            $scope.toValidatedPageNo = 1;

            // Pagination onglet Prix objectif
            $scope.totalPriced = [];
            $scope.pricedPageNo = [];
            $scope.pricedPageNo[enumFactory.IdStateCover.NotCovered] = 1;
            $scope.pricedPageNo[enumFactory.IdStateCover.PendingCover] = 1;

            // Pagination onglet Annulés
            $scope.canceledPageNo = 1;

            // Pagination onglet En attente de signature
            $scope.delayedSigningPageNo = 1;

            $scope.max = 10;
            $scope.idCropSelected = Number(parametersFactory.Parameters.DefaultCrop.Value);
            $scope.idProductSelected = "";
            $scope.idTypeOrderContractSelected = "";
            $scope.idTypeFixationSelected = "";
            $scope.idCompagnySelected = "";
            $scope.dateFilter = "";
            $scope.dateSelected = "";
            $scope.selectedClient = {};
            $scope.viewMode = $routeParams.typeContract;
            $scope.showDetails = true;
            $scope.showDetailsPendingCover = true;
            $scope.showDetailsCover = true;
            $scope.allSelected = false;
            $scope.enumFactory = enumFactory;
            $scope.automaticCover = parametersFactory.Parameters.AutomaticCover.Value;
            $scope.automaticCoverModule = $rootScope.ModuleAutomaticCover.Active;
            $scope.automaticTargetPriceModule = $rootScope.ModuleObjectifAutomatique.Active;
            $scope.fixTargetPrice = parametersFactory.Parameters.FixTargetPrice.Value;
            $scope.moduleElectronicSignatureIsActive = $rootScope.ModuleElectronicSignature.Active;
            $scope.moduleYousignIsActive = $rootScope.ModuleSignatureElectroniqueYouSign.Active;
            $scope.bActivateContractsValidation = parametersFactory.Parameters.bActivateContractsValidation.Value === "1";
            $scope.coverContract = $rootScope.editableObjectsFactory.EditableObjects.Admin_ContractFirmMonitoring_CoverContract.Content;

            $scope.Button_PositionOnMarket = $rootScope.editableObjectsFactory.EditableObjects.Admin_ContractFirmMonitoring_Button_PositionOnMarket.Content;
            $scope.PopUpCoverContractsSelected_Title = $rootScope.editableObjectsFactory.EditableObjects.Admin_ContractFirmMonitoring_PopUpCoverContractsSelected_Title_PositioningConfirmation.Content;
            $scope.PopUpCoverContractsSelected_Text = $rootScope.editableObjectsFactory.EditableObjects.Admin_ContractFirmMonitoring_PopUpCoverContractsSelected_Text.Content;

            $scope.lstProducts = [];
            $scope.lstTypeOrders = [];
            $scope.lstTypeFixations = [];
            $scope.listIdContractsToCover = [];
            $scope.enumIdStateCover = enumFactory.IdStateCover;
            $scope.allContractsInView = [];
            $scope.IsActionFixed = false;
            $scope.FixContract = {
                prixTargetPricedContractFixed: null,
                Iscover: false
            };

            if ($scope.viewMode === "validated")
                idTabContracts = enumFactory.TabContract.Validated;
            else if ($scope.viewMode === "priced")
                idTabContracts = enumFactory.TabContract.Priced;
            else if ($scope.viewMode === "canceled")
                idTabContracts = enumFactory.TabContract.Canceled;
            else if ($scope.viewMode === "pending")
                idTabContracts = enumFactory.TabContract.Pending;
            else if ($scope.viewMode === "toValidated")
                idTabContracts = enumFactory.TabContract.ToValidated;
            else if ($scope.viewMode === "delayedSigning")
                idTabContracts = enumFactory.TabContract.delayedSigning;

            //Permet de gerer les arrondis des prix pour les decimales > 2
            $scope.$watch("allContractsInView", function (n, o) {
                if (n !== o) {
                    var regexfloat = /^[0-9]+.[0-9]{3,100}/;
                    n.forEach(function (item) {
                        if (regexfloat.test(item.Price))
                            item.Price = Number(item.Price).toFixed(2);
                    });
                }
            }, true);

            var refreshUsers = null; // On crée un timer car si on tape trop vite dans le champ de recherche le listing ne correspond pas au nom recherché
            $scope.$watch("selectedIdClient", function (newValue, oldValue) {
                if (newValue && oldValue != newValue) {
                    if (newValue.length >= 3 || newValue.length < oldValue.length) {
                        if (refreshUsers === null)
                            refreshUsers = setTimeout(function () { $scope.Filter(); }, 800);
                        else {
                            clearTimeout(refreshUsers);
                            refreshUsers = setTimeout(function () { $scope.Filter(); }, 800);
                        }
                    }
                }
            });

            var refreshContractors = null; // On crée un timer car si on tape trop vite dans le champ de recherche le listing ne correspond pas au nom recherché
            $scope.$watch("selectedIdContractor", function (newValue, oldValue) {
                if (newValue && oldValue != newValue) {
                    if (newValue.length >= 3 || newValue.length < oldValue.length) {
                        if (refreshContractors === null)
                            refreshContractors = setTimeout(function () { $scope.Filter(); }, 800);
                        else {
                            clearTimeout(refreshContractors);
                            refreshContractors = setTimeout(function () { $scope.Filter(); }, 800);
                        }
                    }
                }
            });

            $scope.$watch("selectedIdContractBase", function (newValue, oldValue) {
                if ((newValue && oldValue !== newValue) || (!newValue && oldValue))
                    $scope.Filter();
            });

            $scope.$watch("dateSelected", function (newValue) {
                if (newValue === "")
                    $scope.dateFilter = "";
                else if (newValue) {
                    //permet de gérer l'internalisation de tout les formats des dates possibles.
                    //Au final nous faisons en sorte de la retrouvé au format yyyy/mm/dd pour l'envoyer à l'API.
                    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                    var dt = new Date(newValue.replace(pattern, '$3-$2-$1'));
                    var currentDate = new Date(dt.toString());
                    var converDate = moment(currentDate);
                    $scope.dateFilter = converDate.format('YYYY/MM/DD');
                }
            });

            $scope.$watch("dateFilter", function (newValue, oldValue) {
                if ((newValue && oldValue !== newValue) || (!newValue && oldValue))
                    $scope.Filter();
            });
        };

        var GetValidatedContractsWithFilter = function (idStateCover) {
            $scope.dataValidated[idStateCover].loading = true;
            $scope.globalLoading = true;

            if (!$scope.selectedIdContract) $scope.selectedIdContract = "";
            if (!$scope.selectedIdClient) $scope.selectedIdClient = "";
            if (!$scope.selectedIdContractBase) $scope.selectedIdContractBase = "";
            contractsService.GetValidatedContractsPaginate(idStateCover, $scope.idCropSelected, $scope.idProductSelected, $scope.idTypeOrderContractSelected, $scope.dateFilter, $scope.selectedIdContract, $scope.selectedIdClient, $scope.idCompagnySelected, $scope.selectedIdContractBase, $scope.max, $scope.validatedPageNo[idStateCover], $scope.dataValidated[idStateCover].sortKey, $scope.dataValidated[idStateCover].reverse, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.total[idStateCover] = data.Total;

                    if (idStateCover === enumFactory.IdStateCover.NotCovered)
                        $scope.contractsNoCover = data.ListVm;
                    else if (idStateCover === enumFactory.IdStateCover.PendingCover)
                        $scope.contractsPendingCover = data.ListVm;
                    else if (idStateCover === enumFactory.IdStateCover.Covered)
                        $scope.contractsCover = data.ListVm;

                    data.ListVm.forEach(function (contract) {
                        $scope.allContractsInView.push(contract);
                    });

                }
                $scope.dataValidated[idStateCover].loading = false;
                var dataValidated = $scope.dataValidated.filter(function (element) { return element.loading === true; });
                $scope.globalLoading = dataValidated.length > 0;
            });
        };

        var GetPendingContractsWithFilter = function (idStateCover) {
            $scope.dataPending[idStateCover].loading = true;
            $scope.globalLoading = true;
            if (!$scope.selectedIdContract) $scope.selectedIdContract = "";
            if (!$scope.selectedIdClient) $scope.selectedIdClient = "";
            contractsService.GetPendingContractsPaginate(idStateCover, $scope.idCropSelected, $scope.idProductSelected, $scope.idTypeOrderContractSelected, $scope.dateFilter, $scope.selectedIdContract, $scope.selectedIdClient, $scope.idCompagnySelected, $scope.max, $scope.pendingPageNo[idStateCover], $scope.dataPending[idStateCover].sortKey, $scope.dataPending[idStateCover].reverse, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.total[idStateCover] = data.Total;

                    if (idStateCover === enumFactory.IdStateCover.NotCovered)
                        $scope.contractsNoCover = data.ListVm;
                    else if (idStateCover === enumFactory.IdStateCover.PendingCover)
                        $scope.contractsPendingCover = data.ListVm;
                    else if (idStateCover === enumFactory.IdStateCover.Covered)
                        $scope.contractsCover = data.ListVm;

                    data.ListVm.forEach(function (contract) {
                        $scope.allContractsInView.push(contract);
                    });

                }
                $scope.dataPending[idStateCover].loading = false;
                var dataPending = $scope.dataPending.filter(function (element) { return element.loading === true; });
                $scope.globalLoading = dataPending.length > 0;
            });
        };

        var GetToValidatedContractsWithFilter = function () {
            $scope.dataToValidated.loading = true;
            $scope.globalLoading = true;
            if (!$scope.selectedIdContract) $scope.selectedIdContract = "";
            if (!$scope.selectedIdClient) $scope.selectedIdClient = "";
            if (!$scope.selectedIdContractor) $scope.selectedIdContractor = "";
            contractsService.GetToValidatedContractsPaginate($scope.idCropSelected, $scope.idProductSelected, $scope.idTypeOrderContractSelected, $scope.dateFilter, $scope.selectedIdContract, $scope.selectedIdClient, $scope.idCompagnySelected, $scope.selectedIdContractor, $scope.max, $scope.toValidatedPageNo, $scope.dataToValidated.sortKey, $scope.dataToValidated.reverse, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.totalToValidated = data.Total;
                    $scope.contractsToValidated = data.ListVm;
                    $scope.allContractsInView = data.ListVm;

                }
                $scope.dataToValidated.loading = false;
                $scope.globalLoading = false;
            });
        };

        var GetCanceledContractsWithFilter = function () {
            $scope.dataCanceled.loading = true;
            $scope.globalLoading = true;
            if (!$scope.selectedIdContract) $scope.selectedIdContract = "";
            if (!$scope.selectedIdClient) $scope.selectedIdClient = "";
            contractsService.GetCanceledContractsPaginate($scope.idCropSelected, $scope.idProductSelected, $scope.idTypeOrderContractSelected, $scope.dateFilter, $scope.selectedIdContract, $scope.selectedIdClient, $scope.idCompagnySelected, $scope.max, $scope.canceledPageNo, $scope.dataCanceled.sortKey, $scope.dataCanceled.reverse, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.totalCanceled = data.Total;
                    $scope.contractsCanceled = data.ListVm;
                    $scope.allContractsInView = data.ListVm;

                }
                $scope.dataCanceled.loading = false;
                $scope.globalLoading = false;
            });
        };

        var GetPricedContractsWithFilter = function (idStateCover) {
            $scope.dataPriced[idStateCover].loading = true;
            $scope.globalLoading = true;
            if (!$scope.selectedIdContract) $scope.selectedIdContract = "";
            if (!$scope.selectedIdClient) $scope.selectedIdClient = "";
            contractsService.GetPricedContractsPaginate(idStateCover, $scope.idCropSelected, $scope.idProductSelected, $scope.idTypeOrderContractSelected, $scope.dateFilter, $scope.selectedIdContract, $scope.selectedIdClient, $scope.idCompagnySelected, $scope.idTypeFixationSelected, $scope.max, $scope.pricedPageNo[idStateCover], $scope.dataPriced[idStateCover].sortKey, $scope.dataPriced[idStateCover].reverse, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.totalPriced[idStateCover] = data.Total;

                    if (idStateCover === enumFactory.IdStateCover.NotCovered)
                        $scope.contractsNoCover = data.ListVm;
                    else if (idStateCover === enumFactory.IdStateCover.PendingCover)
                        $scope.contractsPendingCover = data.ListVm;

                    data.ListVm.forEach(function (contract) {
                        $scope.allContractsInView.push(contract);
                    });
                }
                $scope.dataPriced[idStateCover].loading = false;
                var dataPriced = $scope.dataPriced.filter(function (element) { return element.loading === true; });
                $scope.globalLoading = dataPriced.length > 0;
            });
        };

        var GetDelayedSigningContractsWithFilter = function () {
            $scope.dataDelayedSigning.loading = true;
            $scope.globalLoading = true;
            if (!$scope.selectedIdContract) $scope.selectedIdContract = "";
            if (!$scope.selectedIdClient) $scope.selectedIdClient = "";
            contractsService.GetDelayedSigningContractsPaginate($scope.idCropSelected, $scope.idProductSelected, $scope.dateFilter, $scope.selectedIdClient, $scope.max, $scope.delayedSigningPageNo, $scope.dataDelayedSigning.sortKey, $scope.dataDelayedSigning.reverse, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.totalDelayedSigning = data.Total;
                    $scope.contractsDelayedSigning = data.ListVm;
                    $scope.allContractsInView = data.ListVm;
                }
                $scope.dataDelayedSigning.loading = false;
                $scope.globalLoading = false;
            });
        };

        var GetCrops = function () {
            cropsService.GetCropsVisible(function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    if (data) {
                        $scope.crops = data;
                        var defaultCropInCrops = $filter('filter')($scope.crops, { IdCrop: $scope.idCropSelected })[0] !== undefined;
                        if (!defaultCropInCrops && $scope.crops.length > 0)
                            $scope.idCropSelected = $scope.crops[0].IdCrop;
                        $scope.GetFiltersWithContracts();
                    }
                }
            });
        };

        $scope.GetFiltersWithContracts = function () {
            $scope.idProductSelected = "";
            $scope.idTypeOrderContractSelected = "";
            $scope.idCompagnySelected = "";
            $scope.selectedIdContract = "";
            $scope.dateSelected = "";
            $scope.selectedIdClient = "";
            contractsService.GetFiltersWithContractsByCropAndTabContract($scope.idCropSelected, idTabContracts, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    if (data) {
                        $scope.lstProducts = data.LstProductFilterVm;
                        $scope.lstTypeOrders = data.LstTypeOrderFilterVm;
                        $scope.lstCompagnies = data.LstCompagnies;
                        $scope.lstTypeFixations = data.LstTypeFixationFilterVm;

                        $scope.lstCompagnies.unshift({ IdCompagny: "", Label: "Toutes" });

                        if ($scope.lstProducts.length === 1)
                            $scope.idProductSelected = $scope.lstProducts[0].IdProduct;
                        else if ($scope.lstProducts.length > 1 && $filter('filter')($scope.lstProducts, { IdProduct: "", ProductLabel: "Tous" }).length === 0)
                            $scope.lstProducts.unshift({ IdProduct: "", ProductLabel: "Tous" });

                        if ($scope.lstTypeOrders.length === 1)
                            $scope.idTypeOrderContractSelected = $scope.lstTypeOrders[0].IdTypeOrder;
                        else if ($scope.lstTypeOrders.length > 1 && $filter('filter')($scope.lstTypeOrders, { IdTypeOrder: "", TypeOrderLabel: "Tous" }).length === 0)
                            $scope.lstTypeOrders.unshift({ IdTypeOrder: "", TypeOrderLabel: "Tous" });

                        if ($scope.lstTypeFixations.length === 1)
                            $scope.idTypeFixationSelected = $scope.lstTypeFixations[0].Id;
                        else if ($scope.lstTypeFixations.length > 1 && $filter('filter')($scope.lstTypeFixations, { Id: "", Label: "Tous" }).length === 0)
                            $scope.lstTypeFixations.unshift({ Id: "", Label: "Tous" });

                        $scope.Filter();
                    }
                }
            });
        };

        $scope.SelectAll = function () {
            $scope.allSelected = !$scope.allSelected;
            if ($scope.allSelected) {
                if ($scope.viewMode === "validated" || $scope.viewMode === "pending" || $scope.viewMode === "priced") {
                    contractsService.GetIdContractsToSelect(idTabContracts, enumFactory.IdStateCover.NotCovered, $scope.idCropSelected, $scope.idProductSelected, $scope.idTypeOrderContractSelected, $scope.dateFilter, $scope.selectedIdContract, $scope.selectedIdClient, $scope.idCompagnySelected, $scope.idTypeFixationSelected, function (data2, status2) {
                        if (status2 !== null) {
                            if (status2 === 400)
                                $scope.errorMessage = data2[0].errors[0].Message;
                            else
                                $scope.errorMessage = data2;
                        } else
                            $scope.listIdContractsToCover = data2;
                    });
                }
            } else
                $scope.listIdContractsToCover = [];
        };

        $scope.SelectContract = function (idContract) {
            var index = $scope.listIdContractsToCover.indexOf(idContract);
            if (index >= 0)
                $scope.listIdContractsToCover.splice(index, 1);
            else
                $scope.listIdContractsToCover.push(idContract);
        };

        $scope.CheckContractSelected = function (showPopConfirm) {
            if ($scope.listIdContractsToCover.length > 0) {
                $scope.errorMessagePopUpContractsNoCover = "";
                $scope.errorMessageContractsNoCover = "";
                $scope.FixContract.Iscover = true;
                showPopConfirm();
            }
            else {
                $scope.successMessageContractsNoCover = "";
                $scope.errorMessageContractsNoCover = "Veuillez sélectionner au moins un contrat ";
            }
        };
        
        $scope.CheckPopUpFixedContractSelected = function () {
    		$scope.errorMessagePopUpContractsNoCover = "";
            if ($scope.IsActionFixed && !$scope.FixContract.prixTargetPricedContractFixed)
                $scope.errorMessagePopUpContractsNoCover = $rootScope.editableObjectsFactory.EditableObjects.Admin_ContractFirmMonitoring_PopUpFixedContractsSelected_Error_NoTargetPrice.Content;
          
    		if ($scope.errorMessagePopUpContractsNoCover !== "") {
    			$scope.errorMessagePopUpContractsNoCover = $sce.trustAsHtml($scope.errorMessagePopUpContractsNoCover);
    			return false;
    		}

    		return true;
    	};

        $scope.CoverContractsSelected = function () {
            if ($scope.listIdContractsToCover.length > 0) {
                var isValidatePriceObjectifView = $scope.IsActionFixed ? true : false;
                contractsService.CoverContrats($scope.listIdContractsToCover, isValidatePriceObjectifView, $scope.FixContract.Iscover, $scope.FixContract.prixTargetPricedContractFixed, function (data, status) {
                    if (status !== null) {
                        if (status === 400)
                            $scope.errorMessageContractsNoCover = $sce.trustAsHtml(data[0].errors[0].Message);
                    } else {
                        $scope.Filter();
                        $scope.successMessageContractsNoCover = "Mise à jour effectuée";
                    }
                });
            }
        };

        $scope.GetData = function (pageNo, idStateCover) {
            if ($scope.viewMode === "validated") {
                $scope.validatedPageNo[idStateCover] = pageNo;
                GetValidatedContractsWithFilter(idStateCover);
            }
            else if ($scope.viewMode === "priced") {
                $scope.pricedPageNo[idStateCover] = pageNo;
                GetPricedContractsWithFilter(idStateCover);
            }
            else if ($scope.viewMode === "canceled") {
                $scope.canceledPageNo = pageNo;
                GetCanceledContractsWithFilter();
            }
            else if ($scope.viewMode === "pending") {
                $scope.pendingPageNo[idStateCover] = pageNo;
                GetPendingContractsWithFilter();
            }
            else if ($scope.viewMode === "toValidated") {
                $scope.toValidatedPageNo = pageNo;
                GetToValidatedContractsWithFilter();
            }
            else if ($scope.viewMode === "delayedSigning") {
                $scope.delayedSigningPageNo = pageNo;
                GetDelayedSigningContractsWithFilter();
            }
        };

        $scope.Sort = function (sortKey, reverse, idStateCover) {
            if ($scope.viewMode === "validated") {
                $scope.dataValidated[idStateCover].sortKey = sortKey;
                $scope.dataValidated[idStateCover].reverse = reverse;
                GetValidatedContractsWithFilter(idStateCover);
            }
            else if ($scope.viewMode === "priced") {
                $scope.dataPriced[idStateCover].sortKey = sortKey;
                $scope.dataPriced[idStateCover].reverse = reverse;
                GetPricedContractsWithFilter(idStateCover);
            }
            else if ($scope.viewMode === "canceled") {
                $scope.dataCanceled.sortKey = sortKey;
                $scope.dataCanceled.reverse = reverse;
                GetCanceledContractsWithFilter();
            }
            else if ($scope.viewMode === "pending") {
                $scope.dataPending[idStateCover].sortKey = sortKey;
                $scope.dataPending[idStateCover].reverse = reverse;
                GetPendingContractsWithFilter(idStateCover);
            }
            else if ($scope.viewMode === "toValidated") {
                $scope.dataToValidated.sortKey = sortKey;
                $scope.dataToValidated.reverse = reverse;
                GetToValidatedContractsWithFilter();
            }
            else if ($scope.viewMode === "delayedSigning") {
                $scope.dataDelayedSigning.sortKey = sortKey;
                $scope.dataDelayedSigning.reverse = reverse;
                GetDelayedSigningContractsWithFilter();
            }
        };

        $scope.Filter = function () {
            $scope.errorMessage = "";
            $scope.successMessage = "";
            $scope.errorMessageContractsNoCover = "";
            $scope.successMessageContractsNoCover = "";
            $scope.allContractsInView = [];
            $scope.listIdContractsToCover = [];
            $scope.FixContract.Iscover = false;
            $scope.IsActionFixed = false;

                if ($scope.viewMode === "validated") {
                    GetValidatedContractsWithFilter(enumFactory.IdStateCover.NotCovered); // Récupération des contrats validés non couverts
                    GetValidatedContractsWithFilter(enumFactory.IdStateCover.Covered); // Récupération des contrats validés couverts

                    if ($scope.automaticCover == '1' && $scope.automaticCoverModule)
                        GetValidatedContractsWithFilter(enumFactory.IdStateCover.PendingCover); // Récupération des contrats validés en cours de couverture
                }
                else if ($scope.viewMode === "pending") {
                    GetPendingContractsWithFilter(enumFactory.IdStateCover.NotCovered); // Récupération des contrats en attente non couverts
                    GetPendingContractsWithFilter(enumFactory.IdStateCover.Covered); // Récupération des contrats en attente couverts

                    if ($scope.automaticCover == '1' && $scope.automaticCoverModule)
                        GetPendingContractsWithFilter(enumFactory.IdStateCover.PendingCover); // Récupération des contrats en attente en cours de couverture
                }
                else if ($scope.viewMode === "toValidated")
                    GetToValidatedContractsWithFilter(); // Récupération des contrats à valider
                else if ($scope.viewMode === "priced") {
                    GetPricedContractsWithFilter(enumFactory.IdStateCover.NotCovered); // Récupération des contrats prix objectif non couverts

                    if (($scope.automaticCover == '1' && $scope.automaticCoverModule)  || (!$scope.automaticCoverModule  && !$scope.automaticTargetPriceModule ))
                        GetPricedContractsWithFilter(enumFactory.IdStateCover.PendingCover); // Récupération des contrats prix objectif en cours de couverture
                }
                else if ($scope.viewMode === "canceled")
                    GetCanceledContractsWithFilter(); // Récupération des contrats annulés
                else if ($scope.viewMode === "delayedSigning")
                    GetDelayedSigningContractsWithFilter(); // Récupération des contrats En attente de signature
        };

        $scope.GetDetail = function (idContract, openPopUp, modalTitle) {
            $scope.errorMessage = "";
            $scope.detailedContract = [];
            $scope.detailedIdContract = idContract;
            athenaOrdersService.GetAthenaOrdersVmByIdContract(idContract, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessageContractsNoCover = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    data.forEach(function (item) {
                        if (item.Date !== null)
                            item.Date = $filter('date')(item.Date, "dd/MM/yyyy HH:mm");
                    });
                    $scope.lstAthenaOrdersVm = data;
                    $scope.modalTitle = modalTitle;
                    openPopUp();
                }
            });
        };

        //Pop-up de la fonction
        $scope.ShowPopUpDeleted = function (idContract, openPopUpDeleted) {
            $scope.idContractSelected = idContract;
            openPopUpDeleted();
        };

        //Suppression d'un contrat
        $scope.SetIsLogicallyDeleted = function () {
            contractsService.SetIsLogicallyDeleted($scope.idContractSelected, function (data, status) {
                //Erreur
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    else
                        $location.path("/");
                }
                //Si tout fonctionne
                else {
                    GetCrops();
                }
            });
        };

        //Gestion pop-up contrat vierge à valider

        //Pop-Up Valider
        $scope.ShowPopUpValidatedContract = function (idContract, openPopUpValidatedContract) {
            $scope.MessageValidatedContract = $rootScope.editableObjectsFactory.EditableObjects.Admin_ContractsFirm_ToValidated_PopupValidatedContract_Text.Content + idContract + " ?";
            $scope.idContractSelected = idContract;
            openPopUpValidatedContract();
        };

        $scope.ConfirmValidatedContract = function () {
            $scope.dataToValidated.loading = true;
            contractsService.ValidatedContract($scope.idContractSelected, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.Filter();
                }
            });
        };

        //Pop-Up Refuser
        $scope.ShowPopUpRefusedContract = function (idContract, openPopUpRefusedContract) {
            $scope.MessageRefusedContract = $rootScope.editableObjectsFactory.EditableObjects.Admin_ContractsFirm_ToValidated_PopupRefusedContract_Text.Content + idContract + " ?";
            $scope.idContractSelected = idContract;
            openPopUpRefusedContract();
        };

        $scope.ConfirmRefusedContract = function () {
            $scope.dataToValidated.loading = true;
            contractsService.RefusedContract($scope.idContractSelected, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.Filter();
                }
            });
        };

        var CalculPriceCondPartsOnChange = function(contract) {
            $scope.contractSelected.LstTreeUserGroupConditionSelectedVms = [];
            conditionsService.CheckAndSetGroupConditions($scope.conditionParticulars, $scope.contractSelected.LstTreeUserGroupConditionSelectedVms);

            var totalActuel = 0;

            $scope.contractSelected.LstTreeUserGroupConditionSelectedVms.forEach(function (element) {
                totalActuel += element.Value;
            });

            $scope.totalCondPartDiff = $scope.contractSelected.TotalConditions - totalActuel;

            $scope.contractSelected.NewPrice = Number(contract.IdTypeOrder === enumFactory.TypeOrders.Prixobjectif.IdTypeOrder ? contract.TargetPrice : contract.Price - $scope.totalCondPartDiff).toFixed(2);
        };

        //Pop-Up Modifier
        $scope.ShowPopUpEditedContract = function (contract, openPopUpEditedContract) {
            $scope.editShowValueAutoComplete = true;
            $scope.contractFirm = contract.IdTypeContract == enumFactory.TypeContract.Ferme;
            $scope.contractSelected = contract;
            $scope.contractSelected.NewPrice = contract.IdTypeOrder === enumFactory.TypeOrders.Prixobjectif.IdTypeOrder ? contract.TargetPrice : contract.Price;
            
            $scope.contractSelected.NewQuantity = contract.Quantity;

            conditionsService.GetContractCondParts(contract.IdContract, function (contractCondParts) {
                conditionsService.GetCondParts(contract.IdOffer, contract.IdTree, contract.IdContractCommitment, false, contractCondParts, contract.IdExpiry, function (res) {
                    $scope.errorMessage = res.errorMessage;
                    $scope.conditionParticulars = res.conditionParticulars;
                });
            });

            $scope.ConditionParticularsOnChange = function (condition) {
                $scope.editShowValueAutoComplete = false;
                conditionsService.ConditionParticularsOnChange(contract.IdOffer, condition, '', contract.IdExpiry);
                CalculPriceCondPartsOnChange(contract);
            };
    
            $scope.AutocompletConditionParticularsOnChange = function (selectedValue, condition) {
                condition.selectedValue = selectedValue.originalObject.IdTreeCondition;
                $scope.conditionSelected = condition;
                conditionsService.ConditionParticularsOnChange(contract.IdOffer, condition, '', contract.IdExpiry);
                CalculPriceCondPartsOnChange(contract);
            };
    
            $scope.AutocompleteCondPartSearch = function (str, LstOptions) {
                var matches = [];
                LstOptions.forEach(function (option) {
                    if ((option.Label.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0)) {
                        matches.push(option);
                    }
                });
                return matches;
            };
    
            $scope.inputChanged = function (str) {
                if ($scope.conditionSelected) {
                    $scope.conditionSelected.LstConditionParticularsChilds = [];
                }
            };

            openPopUpEditedContract();
        };

        $scope.ChkEditedContract = function () {
            $scope.editedContractErrorMessage = "";
            $scope.contractSelected.LstTreeUserGroupConditionSelectedVms = [];
            $scope.errorMessage = conditionsService.CheckAndSetGroupConditions($scope.conditionParticulars, $scope.contractSelected.LstTreeUserGroupConditionSelectedVms);

            if ($scope.errorMessage)
                $scope.editedContractErrorMessage = $scope.errorMessage;

            if (!$scope.contractSelected.NewPrice || $scope.contractSelected.NewPrice < 0) {
                $scope.editedContractErrorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_ContractsFirm_ToValidated_PopupEditedContract_MessageError_Price.Content;
            }
            else if (!$scope.contractSelected.NewQuantity || $scope.contractSelected.NewQuantity < 0) {
                $scope.editedContractErrorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_ContractsFirm_ToValidated_PopupEditedContract_MessageError_Tonnage.Content;
            }

            if (!$scope.editedContractErrorMessage) {
                $scope.contractSelected.NewPrice = $scope.contractSelected.NewPrice.toString().replace(",", ".");
                $scope.contractSelected.NewQuantity = $scope.contractSelected.NewQuantity.toString().replace(",", ".");
                return true;
            }
            else {
                return false;
            }
        };

        $scope.ConfirmEditedContract = function () {
            $scope.dataToValidated.loading = true;
            contractsService.EditedContract($scope.contractSelected, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.Filter();
                }
            });
        };

        //Fin Gestion pop-up contrat vierge à valider

        $scope.ValidateFixedContractSelected = function (showPopupFixedContract, item) {
            $scope.listIdContractsToCover = [];
            $scope.PopupFixedContractsSelectedTitle = "Fixer le contrat n° " + item.IdContract;
            $scope.FixContract.prixTargetPricedContractFixed = angular.copy(item.TargetPrice);
            $scope.listIdContractsToCover.push(angular.copy(item.IdContract));
            $scope.IsActionFixed = true;
            showPopupFixedContract();
        };

        $scope.CancelContractPopUp = function (contractSelected, index, showPopUpContract) {
            $scope.PopUpMessageDeleteContract = "";
            $scope.MessageDeleteContractFirmTargetPrice = $rootScope.editableObjectsFactory.EditableObjects.MessageDeleteContractFirmTargetPrice.Content;
            $scope.PopupCanceledContractsSelectedTitle = "Annulation du contrat n° " + contractSelected.IdContract;
            if (contractSelected !== undefined)
                $scope.PopUpMessageDeleteContract = $scope.MessageDeleteContractFirmTargetPrice;

            showPopUpContract(contractSelected);
        };

        $scope.HistoContractPopUp = function (currentContract, openPopUpHisto) {
            contractsService.GetHistoContract(currentContract.IdInit, currentContract.IdCrop, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    else
                        $location.path("/");
                } else {
                    $scope.lstHistoContract = data;
                    $scope.ModalTitle = "Historique du contrat";
                    openPopUpHisto();
                }
            });
        };

        $scope.CancelContract = function (element) {
            contractsService.UpdateContract(element, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    else
                        $location.path("/");
                } else {
                    $scope.successMessage = "Le contrat a bien été annulé.";
                    GetCrops();
                }
            });
        };

        $scope.PopUpContractPendingCover = function (idContract, openPopUpContractPendingCover) {
            $scope.idContractPendingCoverSelected = idContract;
            $scope.coverContractPopUpTitle = $rootScope.editableObjectsFactory.EditableObjects.Admin_ContractFirmMonitoring_CoverContract_PopUp_Title.Content;
            $scope.coverContractPopUpText = $rootScope.editableObjectsFactory.EditableObjects.Admin_ContractFirmMonitoring_CoverContract_PopUp_Text.Content.replace("{{idContract}}", idContract);
            openPopUpContractPendingCover();
        };

        $scope.UpdateContractPendingCover = function () {
            contractsService.UpdateContractPendingCover($scope.idContractPendingCoverSelected, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    else
                        $location.path("/");
                }
                else {
                    GetCrops();
                }
            });
        };

        $scope.ResetListeContractFixed = function () {
            Init();
            GetCrops();
        };

        $scope.ExportValidatedContracts = function () {
            var downloadPath = configurationFactory.Url['Api'] + 'Contracts/GetValidatedContractsXls/' +
                '?idCrop=' + $scope.idCropSelected +
                '&idProduct=' + $scope.idProductSelected +
                '&idTypeOrder=' + $scope.idTypeOrderContractSelected +
                '&date=' + $scope.dateFilter +
                '&idContractStr=' + $scope.selectedIdContract +
                '&numClient=' + $scope.selectedIdClient +
                '&idCompagny=' + $scope.idCompagnySelected +
                '&idContractBaseStr=' + $scope.selectedIdContractBase +
                '&idLanguage=' + $rootScope.language.IdLanguage;
            window.open(downloadPath, '_blank', '');
        };

        $scope.ExportPricedContracts = function () {
            var downloadPath = configurationFactory.Url['Api'] + 'Contracts/GetPricedContractsXls/' +
                '?idCrop=' + $scope.idCropSelected +
                '&idProduct=' + $scope.idProductSelected +
                '&idTypeOrder=' + $scope.idTypeOrderContractSelected +
                '&date=' + $scope.dateFilter +
                '&idContractStr=' + $scope.selectedIdContract +
                '&numClient=' + $scope.selectedIdClient +
                '&idCompagny=' + $scope.idCompagnySelected +
                '&idLanguage=' + $rootScope.language.IdLanguage +
                '&idTypeFixation=' + $scope.idTypeFixationSelected;
            window.open(downloadPath, '_blank', '');
        };

        $scope.ExportCanceledContracts = function () {
            var downloadPath = configurationFactory.Url['Api'] + 'Contracts/GetCanceledContractsXls/' +
                '?idCrop=' + $scope.idCropSelected +
                '&idProduct=' + $scope.idProductSelected +
                '&idTypeOrder=' + $scope.idTypeOrderContractSelected +
                '&date=' + $scope.dateFilter +
                '&idContractStr=' + $scope.selectedIdContract +
                '&numClient=' + $scope.selectedIdClient +
                '&idCompagny=' + $scope.idCompagnySelected +
                '&idLanguage=' + $rootScope.language.IdLanguage;
            window.open(downloadPath, '_blank', '');
        };

        $scope.ExportPendingContracts = function () {
            var downloadPath = configurationFactory.Url['Api'] + 'Contracts/GetPendingContractsXls/' +
                '?idCrop=' + $scope.idCropSelected +
                '&idProduct=' + $scope.idProductSelected +
                '&idTypeOrder=' + $scope.idTypeOrderContractSelected +
                '&date=' + $scope.dateFilter +
                '&idContractStr=' + $scope.selectedIdContract +
                '&numClient=' + $scope.selectedIdClient +
                '&idCompagny=' + $scope.idCompagnySelected +
                '&idLanguage=' + $rootScope.language.IdLanguage;
            window.open(downloadPath, '_blank', '');
        };

        $scope.ExportToValidatedContracts = function () {
            var downloadPath = configurationFactory.Url['Api'] + 'Contracts/GetToValidatedContractsXls/' +
                '?idCrop=' + $scope.idCropSelected +
                '&idProduct=' + $scope.idProductSelected +
                '&idTypeOrder=' + $scope.idTypeOrderContractSelected +
                '&date=' + $scope.dateFilter +
                '&idContractStr=' + $scope.selectedIdContract +
                '&numClient=' + $scope.selectedIdClient +
                '&idCompagny=' + $scope.idCompagnySelected +
                '&nameContractor=' + $scope.selectedIdContractor +
                '&idLanguage=' + $rootScope.language.IdLanguage;
            window.open(downloadPath, '_blank', '');
        };

        $rootScope.onRefresh = function () {
            $scope.ResetListeContractFixed();
        };

        Init();
        GetCrops();
    }]);